.client-image-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  
  &__item {
    flex-grow: 1;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

#client__attest {
  img {
    max-width: 61px;
  }
}

#client__thriva {
  img {
    max-width: 95px;
  }
}

#client__rideshur {
  img {
    margin-bottom: 6px;
  }
}
