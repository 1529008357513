// Colors - site specific declaration
$site-colors: (
  main-pink: #FFEBF0,
  main-pink-darker: #FFDEE6,
  bright-pink: #FF2C9E,
  black: #000000,
  white: #ffffff,
  gray: (
    f8: #f8f8f8,
  ),
  success: purple,
  warning: red,
  secondary: #000000
);
$colors: () !default;
$colors: map-merge($colors, $site-colors);

/* Fonts */
$title-font: 'DM Sans', sans-serif;
$body-font: 'DM Sans', sans-serif;

// Font sizes
$font-h1: 2rem; //32px
$font-h1--desktop: 2.5rem; // 40px
$font-h2: 1.25rem; // 20px
$font-h2--desktop: 1.5rem; // 24px

$font-text-lg: 1.875rem; // 30px

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xlg: 1550px
);

// z-indexes
