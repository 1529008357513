.portrait {
  position: relative;
  height: 100%;

  &__background {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 548px;

    &--webp {
      background-image: url("../../assets/images/liz-portrait.webp");
    }

    &--jpg {
      background-image: url("../../assets/images/liz-portrait.jpg");
    }
  }
}

@include media-breakpoint-up(md) {
  .portrait {
    &__background {
      background-position: center;
      height: 100vh;
      min-height: 100%;
    }
  }
}
