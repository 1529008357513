h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
}

h1,
.h1 {
  font-size: $font-h1;
  font-weight: 400;
  margin: 24px 0;
}

h2,
.h2 {
  font-size: $font-h2;
  font-weight: 700;
  margin: 16px 0;
}

.text {
  &--pink {
    color: color(bright-pink);
  }
}

@include media-breakpoint-up(md) {
  h1 {
    font-size: $font-h1--desktop;
  }

  h2,
  .h2 {
    font-size: $font-h2--desktop;
  }

  .text {
    &--title {
      font-size: $font-text-lg;
    }
  }
}
