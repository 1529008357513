body {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  background: white;
  font-family: $body-font;
  color: black;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

section {
  margin: 64px 0;
  padding: 0 24px;

  &:last-child {
    margin: 64px 0 48px;
  }
}

header {
  margin-top: 32px;
  padding: 0 24px;
}

.divider {
  margin: 0 16px;
  position: relative;
  width: 59px;
  height: 2px;
  display: inline-block;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    top: -4px;
    position: absolute;
    background-color: color(bright-pink);
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: black;
  transition: color 300ms ease-in-out;

  &:hover {
    color: color(bright-pink);
    text-decoration: underline;
  }
}

p {
  line-height: 1.55;
}

ul {
  list-style: none;
  padding-left: 0;
}

:focus {
  outline: color(bright-pink) auto 5px;
}

@include media-breakpoint-up(md) {
  .divider {
    width: 80px;

    &:after {
      top: -7px;
    }
  }

  main {
    display: flex;
  }

  .content {
    width: 50%;
    flex: 0 0 auto;
  }

  header {
    margin-top: 48px;
  }

  section {
    margin: 80px 0;

    &:last-child {
      margin: 80px 0 48px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  section,
  header {
    padding: 0 80px;
  }
}
